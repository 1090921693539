import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import NProgress from 'nprogress';
import axios from 'axios';
import Layout from '../components/layout/layout';
import { searchTrialUrl } from '../constants/config';
import { ResultsTable, ResultsPage } from '../global.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import { Link, graphql } from 'gatsby';
import { Row, Col } from 'reactstrap';
import Pagination from '../components/search/pagination';
import ClampLines from 'react-clamp-lines';
import AdvancedSearch from '../components/search/advanced-search';
import Map from '../components/map/map';
import {
  trackAnalytics,
  filterSearchEventProperties,
} from '../helpers/trackAnalytics';
import { masterDataContext } from '../store/masterDataContext';

const Studies = ({ data, pageContext, location }) => {
  const { masterData } = useContext(masterDataContext);
  const [searchResults, setSearchResults] = useState({});
  const searchParamsRef = useRef({});
  const [totalResults, setTotalResults] = useState();
  useEffect(() => {
    if (location.search) {
      makeSearchRequest(getSearch());
    }
  }, [location]);

  const getSearch = location => {
    let searchParams = null;
    try {
      searchParams = new URL(window ? window.location.href : location.href)
        .searchParams;
    } catch (e) {
      searchParams = new URLSearchParams('');
    }

    let params = {
      PageSize: 10,
      PageIndex: 0,
      SortField: 'Location_Distance',
      SortOrder: 'asc',
      SearchTerm: '',
      Status: '',
      Longitude: '',
      Latitude: '',
      AgeRanges: '',
      Conditions: '',
      TherapeuticArea: '',
      Treatment: '',
      AttachmentTypes: '',
      Phases: '',
      Gender: '',
      HealthyVolunteer: '',
      PatientLevelSharing: '',
      StudyTypes: '',
      StudyResults: '',
      Products: '',
      Collaborators: '',
      MileRadius: '',
      UseAndOperatorForFullTextKeyWordSearch: false,
      zip: '',
      country: '',
    };

    Object.keys(params).forEach(key => {
      if (searchParams.get(key)) {
        params[key] = searchParams.get(key);
      }
    });

    if (params.country.length && !params.zip.length) {
      params.Latitude = '';
      params.Longitude = '';
      params.Countries = params.country;
    }
    return params;
  };

  const orderByField = (fieldName, direction = 'asc') => {
    searchParamsRef.current.SortField = fieldName;
    searchParamsRef.current.SortOrder = direction;
    searchParamsRef.current.PageIndex = 0;
    makeSearchRequest(searchParamsRef.current);
    trackAnalytics('SearchOrder', {
      Field: fieldName,
      Direction: direction,
    });
  };

  const makeSearchRequest = searchParams => {
    searchParamsRef.current = searchParams;
    const eventParams = filterSearchEventProperties(searchParams);
    NProgress.start();
    axios
      .post(searchTrialUrl(), searchParams)
      .then(res => {
        const searchResults = res.data.Data.map(row => {
          return {
            ...row,
          };
        });

        trackAnalytics('SearchResponse', {
          Count: res.data.Count,
          Success: res.data.Success,
          Context: res.data.Context,
          ...eventParams,
        });

        if (res.data.Success) {
          NProgress.done();
          setSearchResults(searchResults);
          setTotalResults(res.data.Count);
        }
      })
      .catch(error => {
        trackAnalytics('SearchResponseFailure', {
          Error: error.message,
          Count: -1,
          Success: false,
          Context: '',
          ...eventParams,
        });
      });
  };

  function buildDisplayFilters(searchParams) {
    let displayData = [];
    let neededParams = [
      'Conditions',
      'StudyTypes',
      'Status',
      'AgeRanges',
      'Gender',
      'Phases',
      'AttachmentTypes',
      'country',
      'Collaborators',
    ];
    if (searchParams['SearchTerm'].length > 1) {
      let displaySec = [];
      displaySec.push(searchParams.SearchTerm);
      displaySec.push(data.searchResultJson.noResultsParams[0]['SearchTerm']);
      displayData.push(displaySec.join(' '));
    }
    if (searchParams['PatientLevelSharing'].length === 0) {
      displayData.push('Trials which do not include Patient Level data');
    } else {
      displayData.push('Trials which include Patient Level data');
    }
    neededParams.forEach(neededParam => {
      Object.keys(searchParams).forEach(param => {
        if (param === neededParam) {
          if (`${searchParams[param]}`.length === 0) {
            displayData.push(
              `All ${data.searchResultJson.noResultsParams[0][param]}`
            );
          } else {
            let displaySec = [];
            let values = `${searchParams[param]}`.split('~');
            values.forEach(internalVal => {
              Object.values(masterData || {}).forEach(dataSection => {
                if (Array.isArray(dataSection)) {
                  dataSection.forEach(item => {
                    if (item.InternalValue === internalVal) {
                      displaySec.push(item.DisplayValue);
                    }
                    if (item.Children && Array.isArray(item.Children)) {
                      item.Children.forEach(subItem => {
                        if (subItem.InternalValue === internalVal) {
                          displaySec.push(subItem.DisplayValue);
                        }
                      });
                    }
                  });
                }
              });
            });
            displaySec.push(data.searchResultJson.noResultsParams[0][param]);
            displayData.push(displaySec.join(' '));
          }
        }
      });
    });
    return displayData.join(', ');
  }
  const goToPage = pageNumber => {
    if (pageNumber - 1 !== searchParamsRef.current.PageIndex) {
      searchParamsRef.current.PageIndex = pageNumber - 1;
      makeSearchRequest(searchParamsRef.current);
    }
  };
  const filteringCondition = conditions => {
    let uniqCondition = [];
    return conditions.filter(cond => {
      if (!uniqCondition.includes(cond.DisplayValue)) {
        uniqCondition.push(cond.DisplayValue);
        return true;
      }
      return false;
    });
  };

  const renderTableRow = (row, index) => {
    return [
      <Tr
        key={'result_' + row.Id}
        className={
          'result-row ' + (index % 2 === 1 ? 'row-unpaired' : 'row-paired')
        }
      >
        <Td className={'condition-section'}>
          {row.Conditions
            ? filteringCondition(row.Conditions)
                .map(condition => condition.DisplayValue)
                .join(', ')
            : undefined}
        </Td>
        <Td className={'status-section'}>
          <div
            className={
              'status-icons ' + `${row.StatusDisplay}`.toLocaleLowerCase()
            }
          />
          <div className={'status-text'}>
            {row.StudyTypeInternal === 'STUDY_TYPE_EXPANDEDACCESS'
              ? `${row.StatusLookup}`
              : row.StatusInternal === 'RECSTATUS_RELIQUISHED'
              ? 'No Longer a ViiV Study'
              : `${row.StatusDisplay}`}
          </div>
        </Td>
        <Td className={'requirements-section'}>
          <div className={'req-div'}>
            {row.GenderDisplay && (
              <img
                src={`/icons/${row.GenderDisplay.toLocaleLowerCase()}.png`}
                alt={row.GenderDisplay}
              />
            )}
            <div className={'req-text'}>
              {row.MinAgeRaw !== null || row.MaxAgeRow !== null
                ? row.MinAgeRaw &&
                  row.MaxAgeRaw &&
                  !row.MaxAgeRaw.includes('N/A') &&
                  !row.MinAgeRaw.includes('N/A')
                  ? `${row.MinAgeRaw} -\n ${row.MaxAgeRaw}`
                  : row.MinAgeRaw && !row.MinAgeRaw.includes('N/A')
                  ? `${row.MinAgeRaw} + `
                  : row.MaxAgeRaw && !row.MaxAgeRaw.includes('N/A')
                  ? `0 - ${row.MaxAge} `
                  : 'No Limit'
                : row.MinAge && row.MaxAge
                ? `${row.MinAge} -\n ${row.MaxAge}`
                : row.MinAge
                ? `${row.MinAge}  + `
                : row.MaxAge
                ? `0 - ${row.MaxAge} `
                : 'No Limit'}
            </div>
          </div>
        </Td>
        <Td
          className={
            'distance-section' +
            (searchParamsRef.current.country.length ? ' align-fix' : '')
          }
        >
          {searchParamsRef.current.country.length ? (
            <strong>
              {row.Location_StateDisplay !== null
                ? `${row.Location_StateDisplay}\n`
                : null}
              {row.Location_CountryDisplay !== null
                ? row.Location_CountryDisplay
                : masterData?.COUNTRY?.find(el =>
                    el.InternalValue.includes(searchParamsRef.current.country)
                  ).DisplayValue}
            </strong>
          ) : (
            data.searchResultJson.noAddressSpecified
          )}
        </Td>
        <Td className={'details-section'}>
          <Link
            className={'view-details'}
            onClick={() => {
              trackAnalytics('TrialDetailsClick', {
                TrialId: row.UniqueIdentifier,
                PositionInList: index,
                TrialTitle: row.Title,
                TrialStatus: row.StatusInternal,
                Conditions: row.Conditions.map(
                  condition => condition.InternalValue
                ),
              });
            }}
            to={`/${pageContext.langKey}/study/?id=${encodeURIComponent(
              `${row.UniqueIdentifier}`
            )}${
              searchParamsRef.current.Latitude.length &&
              searchParamsRef.current.Latitude.length
                ? `&searchLat=${row.Location_Latitude}&searchLong=${
                    row.Location_Longitude
                  }&name=${
                    row.Location_Name !== null ? row.Location_Name : ''
                  }&baseLat=${searchParamsRef.current.Latitude}&baseLong=${
                    searchParamsRef.current.Longitude
                  }`
                : ''
            }`}
          >
            View study details
          </Link>
        </Td>
      </Tr>,
      <Tr
        className={
          (index % 2 === 1 ? 'row-unpaired' : 'row-paired') + ' clampRow'
        }
        key={row.Id}
      >
        <Td className={'clamp-title'} colSpan="5">
          <ClampLines
            key={row.Id}
            text={`${row.UniqueIdentifier} - ${row.Title}`}
            id="text"
            lines={1}
            innerElement="div"
            ellipsis="..."
            moreText={
              <>
                <span className="d-none">{'PlusSquare'}</span>
                <FaRegPlusSquare />
              </>
            }
            lessText={
              <>
                <span className="d-none">{'MinusSquare'}</span>
                <FaRegMinusSquare />
              </>
            }
          />
        </Td>
        <Td className={'details-section mobile'}>
          <Link
            className={'view-details'}
            onClick={() => {
              trackAnalytics('TrialDetailsClick', {
                TrialId: row.UniqueIdentifier,
                PositionInList: index,
                TrialTitle: row.Title,
                TrialStatus: row.StatusInternal,
                Conditions: row.Conditions.map(
                  condition => condition.InternalValue
                ),
              });
            }}
            to={`/${pageContext.langKey}/study/?id=${row.UniqueIdentifier}${
              searchParamsRef.current.Latitude.length &&
              searchParamsRef.current.Latitude.length
                ? `&searchLat=${row.Location_Latitude}&searchLong=${
                    row.Location_Longitude
                  }&name=${
                    row.Location_Name !== null ? row.Location_Name : ''
                  }&baseLat=${searchParamsRef.current.Latitude}&baseLong=${
                    searchParamsRef.current.Longitude
                  }`
                : ''
            }`}
          >
            View study details
          </Link>
        </Td>
      </Tr>,
    ];
  };

  const renderResultsTable = () => {
    if (!searchResults.length) {
      return undefined;
    }
    return (
      <ResultsTable>
        <Table className="results-table">
          {data.searchResultJson.tableHeader.map((headerItem, index) => (
            <Thead className={'table-header'} key={index}>
                <Tr>
                  <Th className={'conditions desktop'}>
                    {headerItem.conditions}
                  </Th>
                  <Th
                    className={'sortable'}
                    onClick={() =>
                      orderByField(
                        'StatusDisplay',
                        searchParamsRef.current.SortField === 'StatusDisplay'
                          ? searchParamsRef.current.SortOrder === 'asc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      )
                    }
                  >
                    {headerItem.status}
                    {searchParamsRef.current.SortField === 'StatusDisplay' ? (
                      searchParamsRef.current.SortOrder === 'asc' ? (
                        <IoMdArrowRoundUp />
                      ) : (
                        <IoMdArrowRoundDown />
                      )
                    ) : (
                      <img src={'/icons/sorting.png'} alt={'sort'} />
                    )}
                  </Th>
                  <Th
                    className={'sortable'}
                    onClick={() =>
                      orderByField(
                        'MinAge',
                        searchParamsRef.current.SortField === 'MinAge'
                          ? searchParamsRef.current.SortOrder === 'asc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      )
                    }
                  >
                    {headerItem.requirements}
                    {searchParamsRef.current.SortField === 'MinAge' ? (
                      searchParamsRef.current.SortOrder === 'asc' ? (
                        <IoMdArrowRoundUp />
                      ) : (
                        <IoMdArrowRoundDown />
                      )
                    ) : (
                      <img src={'/icons/sorting.png'} alt={'sort'} />
                    )}
                  </Th>
                  <Th
                    className={'sortable location'}
                    onClick={() =>
                      orderByField(
                        'Location_Distance',
                        searchParamsRef.current.SortField ===
                          'Location_Distance'
                          ? searchParamsRef.current.SortOrder === 'asc'
                            ? 'desc'
                            : 'asc'
                          : 'asc'
                      )
                    }
                  >
                    {headerItem.distance}
                    {searchParamsRef.current.SortField ===
                    'Location_Distance' ? (
                      searchParamsRef.current.SortOrder === 'asc' ? (
                        <IoMdArrowRoundUp />
                      ) : (
                        <IoMdArrowRoundDown />
                      )
                    ) : (
                      <img src={'/icons/sorting.png'} alt={'sort'} />
                    )}
                  </Th>
                  <Th>
                    <span className={'d-none'}>{'action'}</span>
                    &nbsp;
                  </Th>
                </Tr>
            </Thead>
          ))}

          <Tbody className={'table-body'}>
            {searchResults.map(renderTableRow.bind(this))}
          </Tbody>
        </Table>
      </ResultsTable>
    );
  };

  return (
    <Layout
      meta={{
        pageTitle: `${
          searchParamsRef.current.PageIndex > 0
            ? 'Our Clinical Studies | Page ' +
              (searchParamsRef.current.PageIndex + 1) +
              ' | ViiV'
            : 'Our Clinical Studies | ViiV'
        }`,
        pageDescription: `${
          buildDisplayFilters(getSearch()).length
            ? 'Clinical trials for: '
            : 'All clinical trials for ViiV Healthcare Study Register'
        }${buildDisplayFilters(getSearch())}`,
      }}
    >
      <ResultsPage>
        <Row>
          <Col className={'filter-sec'} lg={3} md={4}>
            <AdvancedSearch
              params={getSearch()}
              pageContext={pageContext}
              searchCallBack={() => {
                makeSearchRequest(getSearch());
              }}
            />
          </Col>
          <Col className={'tabel-sec'} lg={9} md={8}>
            {searchResults.length &&
            searchParamsRef.current.Latitude.length > 0 ? (
              <div className={'map-sec'}>
                <Map
                  data={data.trialDetailsJson.mapSection}
                  studyLocations={searchResults.map(result => ({
                    Latitude: result.Location_Latitude,
                    Longitude: result.Location_Longitude,
                    StudyId: result.UniqueIdentifier,
                    Name: result.Location_Name,
                    City: result.Location_City,
                    StateDisplay: result.Location_StateDisplay,
                    PostalCode: result.Location_PostalCode,
                    CountryDisplay: result.Location_CountryDisplay,
                    conditions:
                      result.Conditions && result.Conditions.length
                        ? result.Conditions.map(
                            condition => condition.DisplayValue
                          )
                            .filter(
                              (val, index, arr) => arr.indexOf(val) === index
                            )
                            .join(', ')
                        : 'N/A',
                  }))}
                />
              </div>
            ) : null}
            {totalResults === 0 ? (
              <p className={'no-results-text'}>
                {`No ViiV Trials were found for your selection of ${buildDisplayFilters(
                  getSearch()
                )}. Please try changing your selections or`}
                <Link to="/en/contact-us">{'contact'}</Link>
                {'ViiV for help'}.
              </p>
            ) : null}
            {totalResults > 10 && (
              <div className={'pagination'}>
                <p>
                  {searchResults && searchResults.length
                    ? `${searchParamsRef.current.PageIndex *
                        searchParamsRef.current.PageSize +
                        1}
                          - ${Math.min(
                            (searchParamsRef.current.PageIndex + 1) *
                              searchParamsRef.current.PageSize,
                            totalResults
                          )} of  ${totalResults}
                          ${data.searchResultJson.results}`
                    : null}
                </p>
                <Pagination
                  activePage={searchParamsRef.current.PageIndex + 1}
                  itemsCountPerPage={searchParamsRef.current.PageSize}
                  totalItemsCount={totalResults}
                  onChange={goToPage}
                />
              </div>
            )}
            {renderResultsTable()}
            {totalResults > 10 && (
              <div className={'bottom-pagination'}>
                <Pagination
                  activePage={searchParamsRef.current.PageIndex + 1}
                  itemsCountPerPage={searchParamsRef.current.PageSize}
                  totalItemsCount={totalResults}
                  onChange={goToPage}
                />
              </div>
            )}
          </Col>
        </Row>
      </ResultsPage>
    </Layout>
  );
};

export default Studies;
Studies.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query SearchResultsQuery {
    trialDetailsJson {
      mapSection {
        site
        condition
        id
        city
        state
        zip
        country
      }
    }
    searchResultJson {
      title
      socialTitle
      viewDetails
      askToJoinBtn
      heroText
      hero2Text
      tableHeader {
        status
        conditions
        distance
        requirements
      }
      noResultsParams {
        SearchTerm
        Conditions
        Status
        Phases
        Gender
        AgeRanges
        StudyTypes
        country
        Collaborators
        AttachmentTypes
      }
      showResult
      results
      noAddressSpecified
    }
  }
`;
